import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';

import {
	ContentToRichText,
	priceFormatting,
	removeSpecialCharacters,
} from 'libs/content';
import SanitizeHtml from 'libs/SanitizeHtml';
import ListStyling from 'libs/SharedStyling';
import ImageAndContent from 'parts/image-and-content/ImageAndContent';
import { AnimationWrap } from 'libs/animations';
import TabSwitch from 'components/forms/TabSwitch';

//#region Styling
const TabsContent = styled.div`
	margin-top: ${p => p.theme.spacing.desktop.medium};
	${p =>
		p.theme.media.smallOnly(css`
			width: 100%;
			padding-top: ${p => p.theme.spacing.desktop.xxsmall};
			text-align: left;
		`)}
	.halfwidth__text {
		.text {
			ul {
				margin-bottom: 20px;
			}
			a[type='button'] {
				margin-bottom: 0 !important;
			}
		}
	}
`;

const TabWrap = styled.div`
	${ListStyling}
	> div {
		padding: 0;
		> .spacing {
			padding-bottom: 0 !important;
		}
	}
	> ul {
		margin-top: 0;
		&:last-of-type {
			margin-bottom: 0;
		}
		> li {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			&:not(:last-of-type) {
				margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
			}
		}
	}

	ul li {
		font-size: 17px;
		line-height: 27px;
		ul li svg[data-icon='caret-right'] {
			top: 3px !important;
		}
	}
`;

const Price = styled.div`
	text-align: right;
	${p =>
		p.theme.media.smallOnly(css`
			width: 75px;
		`)}
`;
//#endregion

export default function Tabs({ tabs, ...props }) {
	const [activeTab, setActiveTab] = useState(
		removeSpecialCharacters(tabs[0]?.title)
	);

	return (
		<>
			<AnimationWrap doanimate={props?.transitions} direction="down">
				<TabSwitch
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					tabs={tabs?.map(tab => ({
						id: removeSpecialCharacters(tab?.title),
						text: tab.title,
					}))}
				/>
			</AnimationWrap>

			<TabsContent>
				{tabs?.map((tab, index) => {
					const tabId = removeSpecialCharacters(tab?.title);

					return (
						<Fragment key={`tabpanel-${index}`}>
							{activeTab === tabId && (
								<TabWrap
									role="tabpanel"
									aria-labelledby={tabId}
									id={`tabpanel-for-${tabId}`}>
									<TextAndImage
										type={tab?.type}
										{...props}
										{...tab}
									/>
								</TabWrap>
							)}
						</Fragment>
					);
				})}
			</TabsContent>
		</>
	);
}

function TextAndImage({ type, transitions = false, ...props }) {
	if (!props?.title && !props?.text && !props?.image) return;

	if (type === 'ContentfulKomponentBildeOgInnhold') {
		return (
			<ImageAndContent
				{...props}
				content={props?.text}
				imageposition={props?.image?.position}
				transitions={transitions}
			/>
		);
	}

	return (
		<>
			{(props?.text?.raw && <ContentToRichText data={props.text} />) ||
				(props?.text?.childMarkdownRemark?.html && (
					<SanitizeHtml html={props?.text} />
				)) || <p>{props?.text}</p>}

			{props?.price?.length === 1 ? (
				<Price>{priceFormatting(props?.price[0])}</Price>
			) : props?.price?.length > 1 ? (
				<ul>
					{props?.price?.map((p, i) => (
						<li key={i}>{p}</li>
					))}
				</ul>
			) : null}
		</>
	);
}
